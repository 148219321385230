import '@ibm/plex/css/ibm-plex.min.css'
import 'animate.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import '../assets/scss/init.scss'

import React, { useRef } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Layout } from '../components/layout/Layout'

const ContactPage: React.FC = () => {
    const contentRef = useRef(null)
    const executeScroll = () => {
        if (contentRef && contentRef.current) {
            const node = contentRef.current as HTMLElement
            node.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            })
        }
    }

    return (
        <Layout>
            <main>
                <div className="page">
                    <div className="landing contact">
                        <div className="text animate__animated animate__fadeInUp">
                            <div className="title">Contact us</div>
                            <div className="desc">
                                Wondering is the ArgatSoft is the right partner for your business?
                            </div>
                            <div className="desc">
                                Let&apos;s get the conversation started to see if there is a fit.
                            </div>
                            <div className="contact">
                                <i className="bi bi-telephone"></i>
                                <span>+1 587 664 3766</span>
                                <i className="bi bi-envelope"></i>
                                <span>info@argatsoft.ca</span>
                            </div>
                        </div>
                        <a className="explore" onClick={executeScroll}>
                            <div className="explore-text">Office Location</div>
                            <div className="explore-arrow bounce-animation"></div>
                        </a>
                        <div className="image-wrap">
                            <StaticImage
                                className="image"
                                src="../images/landing/contact.jpg"
                                alt=""
                                layout="constrained"
                                objectFit="fill"
                                objectPosition="center"
                                quality={100}
                            />
                        </div>
                    </div>
                    <div className="content" ref={contentRef}>
                        <section className="light">
                            <h4 className="text-navy">
                                <span>Office Location</span>
                            </h4>
                            <div className="office">
                                <h5>Calgary</h5>
                            </div>
                            <div className="map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d80269.80128199277!2d-114.12620712277942!3d51.0451237251435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537170039f843fd5%3A0x266d3bb1b652b63a!2sCalgary%2C%20AB!5e0!3m2!1sen!2sca!4v1692819552780!5m2!1sen!2sca"
                                    width="60%"
                                    height="450"
                                    className="border:0;"
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade">
                                </iframe>
                            </div>
                        </section>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default ContactPage
